import React, { useEffect, useState } from "react"
import PageRoot from "../../../components/PageRoot"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Link, navigate } from "gatsby"
import { Button, Modal, Spin, Alert } from "antd"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import {
  formatDate,
  translatePath,
  uiHandleError,
  uiHandleSuccess,
} from "../../../utils"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import useBraintree from "../../../hooks/useBraintree"
import { SUBSCRIPTION_DISABLED } from "../../../config"

const GET_LAST_USER_SUBSCRIPTION_QUERY = gql`
  query getLastUserSubscription {
    user_subscriptions(order_by: { created_at: desc }, limit: 1) {
      id
      state
      start_at
      end_at
      created_at
    }
  }
`

const UserSubscriptionPlan = () => {
  const { t } = useTranslation()
  const pageTitle = t("label:mySubscription")
  const { loading, error, data, refetch } = useQuery(
    GET_LAST_USER_SUBSCRIPTION_QUERY,
    {
      fetchPolicy: "network-only",
    }
  )

  const [cancellingPlan, setCancellingPlan] = useState(false)
  const { cancelSubscription } = useBraintree()

  useEffect(() => {
    if (SUBSCRIPTION_DISABLED) {
      navigate("/me/library", {
        replace: true,
      })
    }
  }, [])

  if (SUBSCRIPTION_DISABLED) {
    return null
  }

  if (loading) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot title={pageTitle} showTitle>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const subscription = data.user_subscriptions[0]

  const handleCancelSubscription = async id => {
    setCancellingPlan(true)
    try {
      await cancelSubscription(id)
      uiHandleSuccess({
        message: t("message:planCancelled"),
        action: () => {},
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setCancellingPlan(false)
  }

  // t("label:Active")
  // t("label:Canceled")
  // t("label:Past Due")

  return (
    <PageRoot title={pageTitle} showTitle>
      <ContainerWithPadding align={"center"}>
        {subscription ? (
          <Spin spinning={cancellingPlan}>
            <div>
              <p>
                {t("label:subscriptionState")} {t(`label:${subscription.state}`)}
              </p>
              <p>
                {t("message:validUntil")}{" "}
                {formatDate(subscription.end_at, {
                  format: t("generic:dateFormat"),
                })}
              </p>
              {subscription.state === "Active" && (
                <Button
                  type="danger"
                  onClick={() => {
                    Modal.confirm({
                      title: t("warning:cancelPlanConfirmation1"),
                      icon: <ExclamationCircleOutlined />,
                      content: t("warning:cancelPlanConfirmation2"),
                      onOk: async () => {
                        await handleCancelSubscription(subscription.id)
                      },
                      onCancel() {},
                      okText: t("button:cancelPlan"),
                    })
                  }}
                >
                  {t("button:cancelPlan")}
                </Button>
              )}
              {subscription.state === "Canceled" && (
                <>
                  <p style={{ margin: 0 }}>{t("message:renewStatement")}</p>

                  <br />
                  <Link to={translatePath("/subscribe")}>
                    <Button className="yellowsp">{t("button:renew")}</Button>
                  </Link>
                </>
              )}
            </div>
          </Spin>
        ) : (
          <div>
            <h2>{t("label:noPlanActive")}</h2>
            <Button type="primary" className="yellow">
              <Link to={translatePath("/subscribe")}>
                {t("button:subscribeNow")}
              </Link>
            </Button>
          </div>
        )}
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default UserSubscriptionPlan
